import { Navigate, Route, Routes } from 'react-router-dom'
import { Admin } from '../pages/Admin'
import { CandidatesPage } from '../pages/Candidates'
import { Companies } from '../pages/Companies'
import { Home } from '../pages/Home'
import { ProjectPageNew } from '../pages/ProjectPageNew'
import { Projects } from '../pages/Projects'
import { RestrictedRoute } from './RestrictedRoute'
import { useAuth } from '../auth/auth-provider'

const RoleBasedHome = () => {
  const { me } = useAuth()

  // Check if user has ROLE_RECRUITER
  if (me?.roles?.includes('ROLE_RECRUITER-CLIENT')) {
    return <Navigate to="/projects" replace />
  }

  // Default home page for other roles
  return <Navigate to="/overview" replace />
}

export const AppRouter = () => {
  return (
    <Routes>
      <Route
        path="/recruiter"
        element={
          <RestrictedRoute
            roles={['ROLE_ADMIN', 'ROLE_RECRUITER', 'ROLE_RECRUITER-ADMIN']}
          >
            <div>Recruiter</div>
          </RestrictedRoute>
        }
      />
      <Route
        path="/recruiter-client"
        element={
          <RestrictedRoute roles={['ROLE_RECRUITER-CLIENT']}>
            <div>RecruiterClient</div>
          </RestrictedRoute>
        }
      />
      <Route path="/unauthorized" element={<div>UNATUTHORIZED</div>} />
      <Route path="/projects/:id?" element={<Projects />} />
      <Route path="/project/:id?" element={<ProjectPageNew />} />
      <Route
        path="/candidates"
        element={
          <RestrictedRoute
            roles={['ROLE_ADMIN', 'ROLE_RECRUITER', 'ROLE_RECRUITER-ADMIN']}
          >
            <CandidatesPage />
          </RestrictedRoute>
        }
      />

      <Route
        path="/companies/:id?"
        element={
          <RestrictedRoute
            roles={['ROLE_ADMIN', 'ROLE_RECRUITER', 'ROLE_RECRUITER-ADMIN']}
          >
            <Companies />
          </RestrictedRoute>
        }
      />
      <Route
        path="/admin"
        element={
          <RestrictedRoute roles={['ROLE_ADMIN']}>
            <Admin />
          </RestrictedRoute>
        }
      />
      <Route path="/overview" element={<Home />} />
      <Route path="/" element={<RoleBasedHome />} />
    </Routes>
  )
}
