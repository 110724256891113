import { Card, Col, Container, Image, Row, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useAuth } from '../auth/auth-provider'
import DashboardSection from '../components/DashboardSection'
import { Restricted } from '../components/Restricted'
import Timestamp from '../components/Timestamp'
import { useCandidates } from '../hooks/queries/use-candidates'
import { useCompanies } from '../hooks/queries/use-companies'
import { useProjects } from '../hooks/queries/use-projects'
import { useReadOnly } from '../hooks/read-only/use-read-only'
import { Candidate } from '../types/candidate'
import { Company } from '../types/company'
import { Project } from '../types/project'

import './Home.css'

export const Home = () => {
  const { me } = useAuth()
  const readOnly = useReadOnly()

  const { data: candidates = [], isLoading: isLoadingCandidates } =
    useCandidates(!readOnly)
  const { data: companies = [], isLoading: isLoadingCompanies } = useCompanies(
    !readOnly
  )
  const { data: projects = [], isLoading: isLoadingProjects } = useProjects()

  // Take only first 10 items for candidates and companies
  const recentCandidates = candidates.slice(0, 10)
  const recentCompanies = companies.slice(0, 10)

  if (
    !readOnly &&
    (isLoadingCandidates || isLoadingCompanies || isLoadingProjects)
  ) {
    return <div>Loading...</div>
  }

  return (
    <Container fluid="lg" className="pt-3">
      <Row xs={1} sm={1} md={2} lg={2} xl={2} className="g-6 mt-4">
        <Col>
          <DashboardSection title="Projekt">
            <Table
              hover
              size="sm"
              className="table-transparent"
              responsive="sm"
            >
              <tbody>
                {projects.map((project: Project) => (
                  <tr key={project.id}>
                    <td>
                      {!readOnly ? (
                        <Link
                          to={`/companies/${project.companyId}`}
                          className="link-primary"
                        >
                          {project.companyName}
                        </Link>
                      ) : (
                        <span>{project.companyName}</span>
                      )}
                    </td>
                    <td>
                      <Link
                        to={`/project/${project.id}`}
                        className="link-success"
                      >
                        {project.title}
                      </Link>
                    </td>
                    <td>
                      {project.updated && (
                        <Timestamp timestamp={project.updated} />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </DashboardSection>
        </Col>
        <Restricted
          roles={['ROLE_ADMIN', 'ROLE_RECRUITER', 'ROLE_RECRUITER-ADMIN']}
        >
          <Col>
            <DashboardSection title="Kandidater">
              <Table
                responsive="sm"
                hover
                size="sm"
                className="table-transparent"
              >
                <tbody>
                  {!readOnly &&
                    recentCandidates.map((candidate: Candidate) => {
                      const image = candidate.avatarId
                        ? `/api/file/${me?.primaryTenantId}/file/${candidate.avatarId}`
                        : '/projectfiles/avatar_anonymous.png'
                      return (
                        <tr key={candidate.id}>
                          <td>
                            <Image
                              roundedCircle
                              src={image}
                              style={{
                                height: '32px',
                                width: '32px',
                                objectFit: 'cover',
                                transform: 'none',
                              }}
                            />
                          </td>
                          <td>
                            <Link to="/candidates" className="link-primary">
                              {candidate.name}
                            </Link>
                          </td>
                          <td>
                            {candidate.projects &&
                              candidate.projects.length > 0 && (
                                <Link
                                  to={`project/${candidate.projects[0].id}`}
                                  className="link-secondary"
                                >
                                  {candidate.projects[0].projectInfoHub
                                    ?.projectTitle ?? '-'}
                                </Link>
                              )}
                          </td>
                          <td>
                            {candidate.updated && (
                              <Timestamp timestamp={candidate.updated} />
                            )}
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </Table>
              {!readOnly && (
                <Card.Link href="#" className="btn btn-primary btn-sm">
                  Ny kandidat ..
                </Card.Link>
              )}
            </DashboardSection>
          </Col>
        </Restricted>
        <Restricted
          roles={['ROLE_ADMIN', 'ROLE_RECRUITER', 'ROLE_RECRUITER-ADMIN']}
        >
          <Col>
            <DashboardSection title="Kunder">
              <Table
                hover
                className="table-transparent"
                size="sm"
                responsive="sm"
              >
                <tbody>
                  {!readOnly &&
                    recentCompanies.map((company: Company) => {
                      const image = company.avatarId
                        ? `/api/file/${me?.primaryTenantId}/file/${company.avatarId}`
                        : '/projectfiles/avatar_anonymous.png'
                      return (
                        <tr key={company.id}>
                          <td style={{ textAlign: 'center', maxWidth: '65px' }}>
                            <img
                              src={image}
                              style={{ maxHeight: '32px', maxWidth: '60px' }}
                              alt={company.name}
                            />
                          </td>
                          <td>
                            <Link
                              to={`/companies/${company.id}`}
                              className="link-primary"
                            >
                              {company.name}
                            </Link>
                          </td>
                          <td>
                            {company.updated && (
                              <Timestamp timestamp={company.updated} />
                            )}
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </Table>
              {!readOnly && (
                <Card.Link href="#" className="btn btn-primary btn-sm">
                  Ny Kund
                </Card.Link>
              )}
            </DashboardSection>
          </Col>
        </Restricted>
      </Row>
    </Container>
  )
}
