import { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import { useAuth } from '../../auth/auth-provider'
import { useUploadFile } from '../../hooks/queries/use-files'
import {
  useProjectInfoHub,
  useUpdateProjectInfoHubImage,
} from '../../hooks/queries/use-projects'
import { useMindmapContext } from '../../providers/mindmap-provider'
import { DropdownAction, Project, ProjectInfoHub } from '../../types/project'

import './ProjectInfoHubEditor.css'

interface ProjectInfoHubEditorProps {
  projectInfoHubId: string
  project: Project
  infoHubChanged: (infoHub: ProjectInfoHub) => void
  dropdownActions: DropdownAction[]
}

export default function ProjectInfoHubEditor({
  projectInfoHubId,
  project,
  infoHubChanged,
  dropdownActions,
}: ProjectInfoHubEditorProps) {
  const MISSING_IMAGE = '/projectfiles/image-missing.svg'

  const { me } = useAuth()
  const { forcedReadOnly: readOnly } = useMindmapContext()

  // State
  const [showLoading, setShowLoading] = useState(false)

  // Queries and Mutations
  const {
    data: projectInfoHub,
    isLoading,
    isSuccess,
    isError,
  } = useProjectInfoHub(project.id ?? '')
  const uploadFileMutation = useUploadFile()
  const updateImageMutation = useUpdateProjectInfoHubImage()

  // Derived state
  const imageSrc = projectInfoHub?.imageId
    ? `/api/file/${me?.primaryTenantId}/file/${projectInfoHub.imageId}`
    : MISSING_IMAGE

  const onDropAccepted = async (acceptedFiles: File[]) => {
    if (!acceptedFiles.length) return

    setShowLoading(true)
    try {
      // Upload the file
      const response = await uploadFileMutation.mutateAsync({
        file: acceptedFiles[0],
        fileType: 'image',
      })

      if (response.length === 1) {
        const imageId = response[0].id
        const updatedInfoHub = await updateImageMutation.mutateAsync({
          infoHubId: projectInfoHubId,
          imageId,
        })
        infoHubChanged(updatedInfoHub)
      } else {
        alert('Error uploading file')
      }
    } catch (error) {
      alert('Error updating image: ' + error)
    } finally {
      setShowLoading(false)
    }
  }

  // Handle info node changes if needed
  // const handleInfoNodeChanged = (infoNode: any) => {}

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error...</div>
  }

  if (!isSuccess) {
    return <div>Not found...</div>
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h4>{projectInfoHub.projectTitle}</h4>
        {!readOnly && dropdownActions.length > 0 && (
          <Dropdown drop="down" align="end">
            <Dropdown.Toggle variant="" id="dropdown-pihe">
              Åtgärder
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {dropdownActions.map((action) => (
                <Dropdown.Item
                  key={action.label}
                  className="d-flex gap-2"
                  onClick={action.onClick}
                >
                  <i className={action.icon}></i>
                  <span>{action.label}</span>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
      <Dropzone onDropAccepted={onDropAccepted} disabled={readOnly}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div
              {...getRootProps()}
              style={{
                border: `1px ${readOnly ? 'solid' : 'dotted'} darkgray`,
                display: 'inline-flex',
                position: 'relative',
                borderRadius: '8px',
                padding: '4px',
              }}
            >
              <input {...getInputProps()} />
              <img
                src={imageSrc}
                className="rounded avatar_img"
                alt="Project"
              />
              {showLoading && <div className="uploading-text">Uploading..</div>}
            </div>
          </section>
        )}
      </Dropzone>
    </>
  )
}
