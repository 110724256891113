import { format, isToday, isYesterday } from 'date-fns'
import { sv } from 'date-fns/locale'
import {
  ChangeEvent,
  FC,
  FormEvent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useAuth } from '../../auth/auth-provider'
import {
  useAddComment,
  useDeleteComment,
  useGetComments,
} from '../../hooks/queries/use-projects'

const MindmapChat: FC<{
  entityId: string
}> = ({ entityId }) => {
  const { me } = useAuth()

  const [newMessage, setNewMessage] = useState<string>('')
  const [editingMessageId, setEditingMessageId] = useState<string | null>(null)
  const createCommentMutation = useAddComment()
  const deleteCommentMutation = useDeleteComment()

  const messagesEndRef = useRef<HTMLDivElement>(null)
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const { data: comments, isLoading, isError } = useGetComments(entityId)

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'auto' })
  }

  useEffect(() => {
    if (!comments || comments.length === 0) return
    scrollToBottom()
  }, [comments])

  // Auto-adjust textarea height
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '0px'
      const scrollHeight = textareaRef.current.scrollHeight
      textareaRef.current.style.height = scrollHeight + 'px'
    }
  }, [newMessage])

  const formatMessageTime = (dateString: string) => {
    const date = new Date(dateString)
    const now = new Date()

    const fullDateTime = format(date, 'yyyy-MM-dd HH:mm', { locale: sv })

    let displayTime
    if (isToday(date)) {
      displayTime = format(date, 'HH:mm', { locale: sv })
    } else if (isYesterday(date)) {
      displayTime = `Igår ${format(date, 'HH:mm', { locale: sv })}`
    } else if (now.getFullYear() === date.getFullYear()) {
      displayTime = format(date, 'd MMM HH:mm', { locale: sv })
    } else {
      displayTime = format(date, 'yyyy-MM-dd HH:mm', { locale: sv })
    }

    return { displayTime, fullDateTime }
  }

  const handleSubmit = async (
    e?: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e?.preventDefault()
    if (!newMessage.trim()) return

    try {
      await createCommentMutation.mutateAsync({
        entityId,
        comment: newMessage.trim(),
      })
      setNewMessage('')
    } catch (error) {
      console.error('Error sending message:', error)
    }
  }

  const handleKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handleSubmit()
    }
  }

  const handleEditMessage = (messageId: string) => {
    setEditingMessageId(messageId)
  }

  const handleDeleteMessage = (commentId: string) => {
    if (commentId) {
      deleteCommentMutation.mutate(
        { commentId },
        {
          onSuccess: () => {
            scrollToBottom()
          },
        }
      )
    }
  }

  const disabled =
    createCommentMutation.isPending || newMessage.trim().length === 0

  return (
    <div className="bg-white d-flex flex-column" style={{ height: '500px' }}>
      <h5 className="p-3 border-bottom">Kommentarer</h5>

      <div className="p-3 overflow-auto flex-grow-1">
        {isLoading ? (
          <div>Laddar kommentarer...</div>
        ) : isError ? (
          <div>Oops, något gick fel! Försök igen.</div>
        ) : !comments || comments.length === 0 ? (
          <div className="d-flex flex-column align-items-center justify-content-center h-100 text-muted gap-3 user-select-none">
            <i className="bi bi-chat-left-dots-fill"></i>
            <span>Inga kommentarer än</span>
          </div>
        ) : (
          comments.map((comment) => {
            const { displayTime, fullDateTime } = formatMessageTime(
              comment.created
            )
            const isCurrentUser = me?.id === comment.userId

            return (
              <div key={comment.id} className="mb-3 position-relative">
                <div className="d-flex align-items-start gap-2">
                  <img
                    src="/projectfiles/avatar_anonymous.png"
                    alt="Avatar"
                    className="rounded-circle"
                    style={{ width: '32px', height: '32px' }}
                  />
                  <div className="flex-grow-1">
                    <div className="d-flex justify-content-between align-items-center mb-1">
                      <span
                        className={`fw-medium ${
                          isCurrentUser ? 'text-success' : ''
                        }`}
                      >
                        {comment.user.firstName} {comment.user.lastName}
                      </span>
                      <div className="d-flex align-items-center gap-2">
                        {isCurrentUser && (
                          <div className="d-flex gap-1">
                            <button
                              onClick={() => handleDeleteMessage(comment.id)}
                              className="btn btn-link p-1"
                            >
                              <i className="bi bi-trash-fill"></i>
                            </button>
                          </div>
                        )}
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-${comment.id}`}>
                              {fullDateTime}
                            </Tooltip>
                          }
                        >
                          <span
                            className="fs-6 text-muted"
                            style={{ cursor: 'default' }}
                          >
                            {displayTime}
                          </span>
                        </OverlayTrigger>
                      </div>
                    </div>
                    <div className="text-break">{comment.text}</div>
                  </div>
                </div>
              </div>
            )
          })
        )}
        <div ref={messagesEndRef} />
      </div>

      <div className="p-3 border-top">
        <form onSubmit={handleSubmit} className="d-flex gap-2">
          <textarea
            ref={textareaRef}
            value={newMessage}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              setNewMessage(e.target.value)
            }
            onKeyDown={handleKeyPress}
            placeholder="Skriv en kommentar..."
            className="form-control"
            style={{
              minHeight: '40px',
              maxHeight: '120px',
              resize: 'none',
            }}
            rows={1}
          />
          <Button
            type="submit"
            disabled={disabled}
            variant="success"
            className="align-self-start"
            style={{ opacity: disabled ? 0.7 : 1 }}
          >
            Skicka
          </Button>
        </form>
      </div>
    </div>
  )
}

export default MindmapChat
