import { useMemo, useState } from 'react'
import { Badge, Dropdown, Modal } from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import { useAuth } from '../../auth/auth-provider'
import { useCandidate } from '../../hooks/queries/use-candidates'
import { useUploadFile } from '../../hooks/queries/use-files'
import {
  useCandidateInfoHub,
  useUpdateCandidateInfoHubImage,
} from '../../hooks/queries/use-projects'
import {
  useUpdateWorkflowStatus,
  useWorkflow,
} from '../../hooks/queries/use-workflows'
import { useMindmapContext } from '../../providers/mindmap-provider'
import { DropdownAction, InfoHub } from '../../types/project'
import { TaskStatus } from '../../types/workflow'
import {
  candidateStatusColors,
  getCurrentTask,
} from '../../utils/candidate-status'
import ProcessSteps from '../ProcessSteps'
import CandidateEditor from '../candidate/CandidateEditor'

import './CandidateInfoHubEditor.css'

interface CandidateInfoHubEditorProps {
  candidateInfoHubId: string
  className?: string
  infoHubChanged: (infoHub: InfoHub) => void
  dropdownActions: DropdownAction[]
}

export default function CandidateInfoHubEditor({
  candidateInfoHubId,
  className,
  infoHubChanged,
  dropdownActions,
}: CandidateInfoHubEditorProps) {
  const MISSING_IMAGE = '/projectfiles/avatar_anonymous.png'

  const { me } = useAuth()
  const { forcedReadOnly: readOnly } = useMindmapContext()

  const [showCandidateEditor, setShowCandidateEditor] = useState(false)
  const [showLoading, setShowLoading] = useState(false)

  // Queries and Mutations
  const {
    data: candidateInfoHub,
    isLoading: isInfoHubLoading,
    isError: isInfoHubError,
  } = useCandidateInfoHub(candidateInfoHubId)

  const {
    data: candidateRecruitmentWorkflow,
    isLoading: isCandidateRecruitmentWorkflowLoading,
    isError: isCandidateRecruitmentWorkflowError,
  } = useWorkflow(candidateInfoHub?.candidateRecruitmentWorkflowId ?? '')

  const {
    data: candidate,
    isLoading: isCandidateLoading,
    isError: isCandidateError,
  } = useCandidate(candidateInfoHub?.candidateId ?? '')

  const uploadFileMutation = useUploadFile()
  const updateImageMutation = useUpdateCandidateInfoHubImage()
  const updateStatusMutation = useUpdateWorkflowStatus()

  const imageSrc = useMemo(() => {
    if (!candidateInfoHub?.imageId || !me) {
      return MISSING_IMAGE
    }
    return `/api/file/${me.primaryTenantId}/file/${candidateInfoHub.imageId}`
  }, [candidateInfoHub, me])

  const onDropAccepted = async (acceptedFiles: File[]) => {
    if (!acceptedFiles.length) return

    setShowLoading(true)
    try {
      // Upload the file
      const response = await uploadFileMutation.mutateAsync({
        file: acceptedFiles[0],
        fileType: 'image',
      })

      if (response.length === 1) {
        const imageId = response[0].id
        const updatedInfoHub = await updateImageMutation.mutateAsync({
          candidateInfoHubId,
          imageId,
        })
        infoHubChanged(updatedInfoHub)
      } else {
        alert('Error uploading file')
      }
    } catch (error) {
      alert('Error updating image: ' + error)
    } finally {
      setShowLoading(false)
    }
  }

  // If you need to handle InfoNode changes
  // const handleInfoNodeChanged = (infoNode: InfoNode) => {}

  if (
    isInfoHubLoading ||
    isCandidateLoading ||
    isCandidateRecruitmentWorkflowLoading
  ) {
    return <div>Laddar...</div>
  }

  if (
    isInfoHubError ||
    isCandidateError ||
    isCandidateRecruitmentWorkflowError
  ) {
    return <div>Något gick fel! Ladda om sidan.</div>
  }

  const handleStatusChanged = (taskId: string, status: TaskStatus) => {
    const dueDateTime = new Date().toISOString()
    updateStatusMutation.mutate({
      taskId,
      status,
      workflowId: candidateInfoHub?.candidateRecruitmentWorkflowId ?? '',
      updatedDateTime: dueDateTime,
      dueDateTime,
    })
  }

  const currentTask = getCurrentTask(candidateRecruitmentWorkflow?.tasks ?? [])

  return (
    <div className={className}>
      {candidateInfoHub && (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="text-primary d-flex align-items-center gap-2">
              <i className="bi bi-person"></i>
              {candidateInfoHub.candidateName}{' '}
              {currentTask && (
                <Badge
                  style={{
                    backgroundColor: candidateStatusColors[currentTask.status],
                  }}
                  className="fs-6"
                  bg="none"
                >
                  {currentTask.title}
                </Badge>
              )}
            </h3>
            {!readOnly && dropdownActions.length > 0 && (
              <Dropdown drop="down" align="end">
                <Dropdown.Toggle variant="primary" id="dropdown-cihe">
                  Åtgärder
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {[
                    {
                      icon: 'bi-pencil-fill',
                      label: 'Redigera',
                      onClick: () => setShowCandidateEditor(true),
                    },
                    ...dropdownActions,
                  ].map((action) => (
                    <Dropdown.Item
                      key={action.label}
                      className="d-flex gap-2"
                      onClick={action.onClick}
                    >
                      <i className={action.icon}></i>
                      <span>{action.label}</span>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div>
              <Dropzone onDropAccepted={onDropAccepted} disabled={readOnly}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div
                      {...getRootProps()}
                      style={{
                        border: `1px ${readOnly ? 'solid' : 'dotted'} darkgray`,
                        display: 'inline-flex',
                        position: 'relative',
                        borderRadius: '8px',
                        padding: '4px',
                      }}
                    >
                      <input {...getInputProps()} />
                      <img
                        src={imageSrc}
                        className="rounded avatar_img"
                        alt="Candidate avatar"
                      />
                      {showLoading && (
                        <div className="uploading-text">Uploading..</div>
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            <div style={{ marginLeft: '10px' }}></div>
            {candidate && (
              <div>
                <a
                  href={`tel:${candidate?.phoneNumber}`}
                  className="text-decoration-none d-flex gap-2"
                >
                  <i className="bi bi-telephone"></i>
                  <span>{candidate?.phoneNumber}</span>
                </a>
                <a
                  href={`mailto:${candidate?.email}`}
                  className="text-decoration-none d-flex gap-2"
                >
                  <i className="bi bi-envelope"></i>
                  <span>{candidate?.email}</span>
                </a>
                <a
                  href={candidate?.linkedInUrl}
                  className="text-decoration-none d-flex gap-2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="bi bi-linkedin"></i>
                  <span>LinkedIn</span>
                </a>

                {/* link to edit candidate */}
                {!readOnly && (
                  <Modal
                    show={showCandidateEditor}
                    onHide={() => setShowCandidateEditor(false)}
                    size="xl"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>{candidate?.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <CandidateEditor
                        id={candidate?.id!}
                        onClose={() => setShowCandidateEditor(false)}
                      />
                    </Modal.Body>
                  </Modal>
                )}
              </div>
            )}
          </div>
          {candidateRecruitmentWorkflow && (
            <ProcessSteps
              steps={candidateRecruitmentWorkflow.tasks}
              onStatusChanged={handleStatusChanged}
              disabled={readOnly}
            />
          )}
        </>
      )}
    </div>
  )
}
