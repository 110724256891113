import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, Form, FormGroup } from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import { useAuth } from '../../auth/auth-provider'
import {
  useCompany,
  useCreateCompany,
  useUpdateCompany,
} from '../../hooks/queries/use-companies'
import { useUploadFile } from '../../hooks/queries/use-files'
import { useCreateUser, useCreateUsers } from '../../hooks/queries/use-users'
import { Company, CreateCompanyAction } from '../../types/company'
import { User } from '../../types/user'

import './CompanyEditor.css'
import CompanyContacts from './ContactPersonManager'

const AVATAR_MISSING = '/projectfiles/avatar-missing.png'

export type CompanyContact = Partial<User> & {
  isNew: boolean
}

export default function CompanyEditor({
  id,
  onClose,
}: {
  id?: string
  onClose?: () => void
}) {
  const [edited, setEdited] = useState<Company>()
  const [contacts, setContacts] = useState<CompanyContact[]>([])

  const { me } = useAuth()

  const original = useCompany(id || '')

  const createCompany = useCreateCompany()
  const updateCompany = useUpdateCompany()
  const uploadFile = useUploadFile()
  const createUser = useCreateUser()

  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    if (original.data) {
      setEdited(original.data)
      setContacts(
        original.data.contacts.map((contact) => ({
          ...contact,
          isNew: false,
        }))
      )
    }
  }, [original.data])

  const onDropAccepted = (acceptedFiles: File[]) => {
    uploadFile.mutate(
      { file: acceptedFiles[0], fileType: 'image' },
      {
        onSuccess: (data) => {
          setEdited({
            ...edited!,
            avatarId: data[0].id,
          })
        },
      }
    )
  }

  const createNewContacts = async () => {
    const newContacts = contacts.filter((contact) => contact.isNew)
    const newUserIds = [] as string[]

    for (const newContact of newContacts) {
      const user = await createUser.mutateAsync({
        user: newContact as User,
        roles: ['ROLE_RECRUITER-CLIENT'],
      })

      newUserIds.push(user.id)
    }
    return newUserIds
  }

  const save = async () => {
    if (original.isSuccess && original.data) {
      const newUserIds = await createNewContacts()
      const contactIds = edited?.contactUserIds ?? []
      updateCompany.mutate(
        {
          original: original.data,
          edited: {
            ...edited,
            contactUserIds: [...contactIds, ...newUserIds],
          } as Company,
        },
        {
          onSuccess: () => {
            onClose?.()
          },
          onError: (error) => {
            setError(
              'Något gick fel när kunden skulle uppdateras. Vänligen försök igen.'
            )
          },
        }
      )
    } else {
      createCompany.mutate(
        {
          about: edited?.about ?? '',
          avatarId: edited?.avatarId ?? '',
          name: edited?.name ?? '',
          tags: edited?.tags ?? [],
          contacts: contacts.map((contact) => ({
            firstName: contact.firstName ?? '',
            lastName: contact.lastName ?? '',
            email: contact.email ?? '',
            phone: contact.phone ?? '',
          })),
        },
        {
          onSuccess: (data) => {
            id = data.id
            onClose?.()
          },
          onError: (error) => {
            setError(
              'Något gick fel när kunden skulle skapas. Vänligen försök igen.'
            )
          },
        }
      )
    }
    /*     const contactsToCreate = contacts.filter((contact) => contact.isNew)

    createUsers.mutate(
      { users: contactsToCreate as User[], roles: ['ROLE_RECRUITER-CLIENT'] },
      {
        onSuccess: (data) => {
          const contactUserIds = [
            ...contacts.filter((c) => !c.isNew).map((c) => c.id!),
            ...data.map((c) => c.id),
          ]

        },
        onError: (error) => {
          setError(
            'Något gick fel när användarna skulle skapas! Kontrollera så att alla mailadresser är unika.'
          )
        },
      }
    ) */
  }

  const isLoading =
    uploadFile.isPending || createUser.isPending || updateCompany.isPending

  if (original.isLoading) {
    return <div>Loading...</div>
  }

  return (
    <>
      {error && (
        <Alert variant="danger" className="mt-2">
          {error}
        </Alert>
      )}
      <Form className="row g-3 form-tight">
        <Col md="12" className="d-inline-flex">
          <div>
            <Dropzone
              onDropAccepted={onDropAccepted}
              accept={{
                'image/jpeg': ['.jpg', '.jpeg'],
                'image/png': ['.png', '.PNG'],
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()} className="dropzone-container">
                    <input {...getInputProps()} />
                    <img
                      src={
                        edited?.avatarId
                          ? `/api/file/${me?.primaryTenantId}/file/` +
                            edited.avatarId
                          : AVATAR_MISSING
                      }
                      alt="Company Avatar"
                      className="rounded"
                    />
                    {uploadFile.isPending && (
                      <div className="uploading-text">Uploading..</div>
                    )}
                  </div>
                </section>
              )}
            </Dropzone>
            <div className="d-flex">
              <small className="ms-2 text-secondary">Logotyp</small>
              {edited?.avatarId && (
                <button className="ms-auto btn btn-link p-0 border-0">
                  <i
                    className="bi bi-trash"
                    onClick={() => setEdited({ ...edited!, avatarId: '' })}
                  ></i>
                </button>
              )}
            </div>
          </div>
        </Col>
        <Col md="12">
          <FormGroup className="flex-fill">
            <Form.Label>Namn</Form.Label>
            <Form.Control
              value={edited?.name ?? ''}
              onChange={(e) => {
                setEdited({ ...edited!, name: e.target.value })
              }}
            ></Form.Control>
          </FormGroup>
        </Col>
        <Col md="12">
          <FormGroup className="flex-fill">
            <Form.Label>Om</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={edited?.about ?? ''}
              onChange={(e) => {
                setEdited({ ...edited!, about: e.target.value })
              }}
            ></Form.Control>
          </FormGroup>
        </Col>
        {edited && (
          <CompanyContacts
            contacts={contacts}
            setContacts={setContacts}
            isLoading={isLoading}
            currentCompany={edited}
            setError={setError}
          />
        )}
      </Form>

      <div className="mt-4 gm-2 d-flex justify-content-end">
        <Button onClick={() => setEdited(original.data)} disabled={isLoading}>
          Ångra
        </Button>
        <Button className="ms-2" onClick={save} disabled={isLoading}>
          Spara
        </Button>
      </div>
    </>
  )
}
