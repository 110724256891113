import { InfoNodeAddFilePatch } from '../hooks/queries/use-projects'
import {
  CandidateInfoHub,
  CreateProjectAction,
  InfoNode,
  MindmapComment,
  NotifyProjectAction,
  Project,
  ProjectInfoHub,
} from '../types/project'
import { ApiClient } from './api-client'

export class ProjectService {
  private basePath: string
  private basePathWithoutTenantId: string

  constructor(private apiClient: ApiClient) {
    this.basePath = apiClient.getBaseUrl('project')
    this.basePathWithoutTenantId = apiClient.getBaseUrl('project', {
      useTenantId: false,
    })
  }

  async getProjects(): Promise<Project[]> {
    return this.apiClient.get<Project[]>(`${this.basePath}/project`)
  }

  async getProject(id: string): Promise<Project> {
    return this.apiClient.get<Project>(`${this.basePath}/project/${id}`)
  }

  async createProject(project: CreateProjectAction): Promise<Project> {
    return this.apiClient.post<Project>(`${this.basePath}/project`, project)
  }

  async updateProject(original: Project, edited: Project): Promise<Project> {
    const patch = this.createPatch(original, edited)
    return this.apiClient.patch<Project>(
      `${this.basePath}/project/${original.id}`,
      patch
    )
  }

  async sendNotifications(
    notifyProjectAction: NotifyProjectAction
  ): Promise<void> {
    return this.apiClient.post(
      `${this.basePath}/@notifyProject`,
      notifyProjectAction
    )
  }

  async deleteProject(id: string): Promise<void> {
    return this.apiClient.delete(`${this.basePath}/project/${id}`)
  }

  async getProjectInfoHub(projectId: string): Promise<ProjectInfoHub> {
    return this.apiClient.get<ProjectInfoHub>(
      `${this.basePath}/project/${projectId}/projectInfoHub`
    )
  }

  async getCandidateInfoHub(
    candidateInfoHubId: string
  ): Promise<CandidateInfoHub> {
    return this.apiClient.get<CandidateInfoHub>(
      `${this.basePath}/candidateInfoHub/${candidateInfoHubId}`
    )
  }

  async patchCandidateInfoHub(
    candidateInfoHubId: string,
    patch: Partial<CandidateInfoHub>
  ): Promise<CandidateInfoHub> {
    return this.apiClient.patch<CandidateInfoHub>(
      `${this.basePath}/candidateInfoHub/${candidateInfoHubId}`,
      patch
    )
  }

  async patchProjectInfoHub(
    projectInfoHubId: string,
    patch: Partial<ProjectInfoHub>
  ): Promise<ProjectInfoHub> {
    return this.apiClient.patch<ProjectInfoHub>(
      `${this.basePath}/projectInfoHub/${projectInfoHubId}`,
      patch
    )
  }

  async getInfoNode(infoNodeId: string): Promise<InfoNode> {
    return this.apiClient.get<InfoNode>(
      `${this.basePath}/infoNode/${infoNodeId}`
    )
  }

  async updateInfoNode(
    infoNodeId: string,
    patch: Partial<InfoNode>
  ): Promise<InfoNode> {
    return this.apiClient.patch<InfoNode>(
      `${this.basePath}/infoNode/${infoNodeId}`,
      patch
    )
  }

  async updateInfoNodeFile(
    infoNodeId: string,
    patch: InfoNodeAddFilePatch
  ): Promise<InfoNode> {
    return this.apiClient.patch<InfoNode>(
      `${this.basePath}/infoNode/${infoNodeId}`,
      patch
    )
  }

  async deleteInfoNode(infoNodeId: string): Promise<void> {
    return this.apiClient.delete(`${this.basePath}/infoNode/${infoNodeId}`)
  }

  async createInfoNode(
    infoHubId: string,
    infoNode: InfoNode
  ): Promise<InfoNode> {
    return this.apiClient.post<InfoNode>(
      `${this.basePath}/infoHub/${infoHubId}/infoNode`,
      infoNode
    )
  }

  async deleteInfoNodeFile(infoNodeId: string, fileId: string): Promise<void> {
    return this.apiClient.delete(
      `${this.basePath}/infoNode/${infoNodeId}/file/${fileId}`
    )
  }

  async deleteInfoNodeLink(infoNodeId: string, linkId: string): Promise<void> {
    return this.apiClient.delete(
      `${this.basePath}/infoNode/${infoNodeId}/link/${linkId}`
    )
  }

  async deleteInfoHub(infoHubId: string): Promise<void> {
    return this.apiClient.delete(`${this.basePath}/infoHub/${infoHubId}`)
  }

  async suggestRichTextContent(
    action: string,
    project: Project
  ): Promise<string> {
    if (action === 'FORMAL_REQUIREMENTS') {
      const url = `${this.basePathWithoutTenantId}/@suggestFormalRequirements?position=${project.position}`
      return this.apiClient.get<string>(url)
    }
    throw new Error('Unknown suggestAction: ' + action)
  }

  async addCandidatesToProject(
    projectId: string,
    candidateIds: string[]
  ): Promise<Project> {
    return this.apiClient.patch<Project>(
      `${this.basePath}/project/${projectId}`,
      { addCandidates: candidateIds }
    )
  }

  async getComments(entityId: string): Promise<MindmapComment[]> {
    return this.apiClient.get<MindmapComment[]>(`${this.basePath}/comment`, {
      params: {
        entityId,
      },
    })
  }

  async addComment(entityId: string, comment: string): Promise<void> {
    return this.apiClient.post<void>(`${this.basePath}/comment`, {
      comment,
      entityId,
    })
  }

  async deleteComment(commentId: string): Promise<void> {
    return this.apiClient.delete<void>(`${this.basePath}/comment/${commentId}`)
  }

  private createPatch(original: Project, edited: Project): Partial<Project> {
    const patch: Partial<Project> = {}

    if (original.title !== edited.title) patch.title = edited.title
    if (original.position !== edited.position) patch.position = edited.position
    if (original.companyId !== edited.companyId)
      patch.companyId = edited.companyId
    if (original.backgroundImageId !== edited.backgroundImageId)
      patch.backgroundImageId = edited.backgroundImageId
    if (original.companyName !== edited.companyName)
      patch.companyName = edited.companyName

    if (original.recruiter?.id !== edited.recruiter?.id) {
      patch.recruiterUserId = edited.recruiter?.id
    }
    if (original.mainContact?.id !== edited.mainContact?.id) {
      patch.mainContactUserId = edited.mainContact?.id
    }

    if (original.desiredStartDate !== edited.desiredStartDate)
      patch.desiredStartDate = edited.desiredStartDate
    if (original.companyContactName !== edited.companyContactName)
      patch.companyContactName = edited.companyContactName
    if (original.companyContactEmail !== edited.companyContactEmail)
      patch.companyContactEmail = edited.companyContactEmail
    if (original.companyContactPhone !== edited.companyContactPhone)
      patch.companyContactPhone = edited.companyContactPhone
    if (original.status !== edited.status) patch.status = edited.status

    if (
      edited.coRecruiters &&
      !this.arrayEquals(original.coRecruiters ?? [], edited.coRecruiters)
    ) {
      patch.coRecruiterUserIds = edited.coRecruiters.map((x) => x.id)
    }

    if (edited.tags && !this.arrayEquals(original.tags ?? [], edited.tags)) {
      patch.tags = edited.tags.map((t) => (typeof t === 'string' ? t : t.name))
    }

    if (
      edited.contacts &&
      !this.arrayEquals(original.contacts ?? [], edited.contacts)
    ) {
      patch.contactUserIds = edited.contacts.map((contact) => contact.id)
    }

    return patch
  }

  private arrayEquals<T>(a: T[], b: T[]): boolean {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    )
  }
}
