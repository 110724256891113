import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query'
import {
  CandidateInfoHub,
  CandidateStatus,
  CreateProjectAction,
  InfoNode,
  NotifyProjectAction,
  Project,
} from '../../types/project'
import { useApiClient } from '../useApiClient'
import { Workflow } from '../../types/workflow'

export interface InfoNodeAddFilePatch {
  addFile: {
    id: string
    fileName: string
    contentType: string
  }
}

export const projectKeys = {
  all: ['projects'] as const,
  lists: () => [...projectKeys.all, 'list'] as const,
  list: (filters: string) => [...projectKeys.lists(), { filters }] as const,
  details: () => [...projectKeys.all, 'detail'] as const,
  detail: (id: string) => [...projectKeys.details(), id] as const,
  infoHub: () => [...projectKeys.all, 'infoHub'] as const,
  projectInfoHub: (projectId: string) =>
    [...projectKeys.infoHub(), 'project', projectId] as const,
  candidateInfoHub: (hubId: string) =>
    [...projectKeys.infoHub(), 'candidate', hubId] as const,
  infoNode: (nodeId: string) =>
    [...projectKeys.infoHub(), 'node', nodeId] as const,
  mindmap: (id: string) => [...projectKeys.detail(id), 'mindmap'] as const,
  comments: (entityId: string) => ['comments', entityId] as const,
}

export const useProjects = (enabled = true) => {
  const { projectsService } = useApiClient()

  return useQuery({
    queryKey: projectKeys.lists(),
    queryFn: () => projectsService.getProjects(),
    enabled,
  })
}

export const useProject = (id: string) => {
  const { projectsService } = useApiClient()

  return useQuery({
    queryKey: projectKeys.detail(id),
    queryFn: () => projectsService.getProject(id),
    enabled: !!id,
  })
}

export type CandidateInfoHubWithWorkflow = CandidateInfoHub & {
  candidateRecruitmentWorkflow: Workflow
}

export type ProjectWithWorkflows = Omit<Project, 'candidateInfoHubs'> & {
  candidateInfoHubs: CandidateInfoHubWithWorkflow[]
}

export const useProjectMindmap = (
  id: string
): UseQueryResult<ProjectWithWorkflows, Error> => {
  const { projectsService, workflowsService } = useApiClient()

  return useQuery({
    queryKey: projectKeys.mindmap(id),
    queryFn: async () => {
      const project = await projectsService.getProject(id)
      const candidateInfoHubs = await Promise.all(
        project.candidateInfoHubs?.map(async (hub) => ({
          ...hub,
          candidateRecruitmentWorkflow: await workflowsService.getWorkflow(
            hub.candidateRecruitmentWorkflowId
          ),
        })) || []
      )
      return { ...project, candidateInfoHubs }
    },
    enabled: !!id,
  })
}

export const useCreateProject = () => {
  const queryClient = useQueryClient()
  const { projectsService } = useApiClient()

  return useMutation({
    mutationFn: (project: CreateProjectAction) =>
      projectsService.createProject(project),
    onSuccess: () => {
      queryClient.invalidateQueries()
    },
  })
}

export const useUpdateProject = () => {
  const queryClient = useQueryClient()
  const { projectsService } = useApiClient()

  return useMutation({
    mutationFn: ({
      original,
      edited,
    }: {
      original: Project
      edited: Project
    }) => projectsService.updateProject(original, edited),
    onSuccess: (_, { edited }) => {
      queryClient.invalidateQueries()
    },
  })
}

export const useDeleteProject = () => {
  const queryClient = useQueryClient()
  const { projectsService } = useApiClient()

  return useMutation({
    mutationFn: (id: string) => projectsService.deleteProject(id),
    onSuccess: (_, id) => {
      queryClient.invalidateQueries()
    },
  })
}

export const useProjectInfoHub = (projectId: string) => {
  const { projectsService } = useApiClient()

  return useQuery({
    queryKey: projectKeys.projectInfoHub(projectId),
    queryFn: () => projectsService.getProjectInfoHub(projectId),
    enabled: !!projectId,
  })
}

export const useCandidateInfoHub = (hubId: string) => {
  const { projectsService } = useApiClient()

  return useQuery({
    queryKey: projectKeys.candidateInfoHub(hubId),
    queryFn: () => projectsService.getCandidateInfoHub(hubId),
    enabled: !!hubId,
  })
}

export const useInfoNode = (nodeId: string) => {
  const { projectsService } = useApiClient()

  return useQuery({
    queryKey: projectKeys.infoNode(nodeId),
    queryFn: () => projectsService.getInfoNode(nodeId),
    enabled: !!nodeId,
  })
}

export const useUpdateInfoNode = () => {
  const queryClient = useQueryClient()
  const { projectsService } = useApiClient()

  return useMutation({
    mutationFn: ({ id, patch }: { id: string; patch: Partial<InfoNode> }) =>
      projectsService.updateInfoNode(id, patch),
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries()
    },
  })
}

export const useCreateInfoNode = () => {
  const queryClient = useQueryClient()
  const { projectsService } = useApiClient()

  return useMutation({
    mutationFn: ({ hubId, node }: { hubId: string; node: InfoNode }) =>
      projectsService.createInfoNode(hubId, node),
    onSuccess: (data) => {
      queryClient.invalidateQueries()
    },
  })
}

export const useDeleteInfoNode = () => {
  const queryClient = useQueryClient()
  const { projectsService } = useApiClient()

  return useMutation({
    mutationFn: (nodeId: string) => projectsService.deleteInfoNode(nodeId),
    onSuccess: (_, nodeId) => {
      queryClient.invalidateQueries()
    },
  })
}

export const useDeleteInfoNodeFile = () => {
  const queryClient = useQueryClient()
  const { projectsService } = useApiClient()

  return useMutation({
    mutationFn: ({ nodeId, fileId }: { nodeId: string; fileId: string }) =>
      projectsService.deleteInfoNodeFile(nodeId, fileId),
    onSuccess: (_, { nodeId }) => {
      queryClient.invalidateQueries()
    },
  })
}

export const useDeleteInfoNodeLink = () => {
  const queryClient = useQueryClient()
  const { projectsService } = useApiClient()

  return useMutation({
    mutationFn: ({ nodeId, linkId }: { nodeId: string; linkId: string }) =>
      projectsService.deleteInfoNodeLink(nodeId, linkId),
    onSuccess: (_, { nodeId }) => {
      queryClient.invalidateQueries()
    },
  })
}

export const useAddCandidatesToProject = () => {
  const queryClient = useQueryClient()
  const { projectsService } = useApiClient()

  return useMutation({
    mutationFn: ({
      projectId,
      candidateIds,
    }: {
      projectId: string
      candidateIds: string[]
    }) => projectsService.addCandidatesToProject(projectId, candidateIds),
    onSuccess: (_, { projectId }) => {
      queryClient.invalidateQueries()
    },
  })
}

export const useUpdateCandidateInfoHubImage = () => {
  const queryClient = useQueryClient()
  const { projectsService } = useApiClient()

  return useMutation({
    mutationFn: ({
      candidateInfoHubId,
      imageId,
    }: {
      candidateInfoHubId: string
      imageId: string
    }) =>
      projectsService.patchCandidateInfoHub(candidateInfoHubId, { imageId }),
    onSuccess: (_, { candidateInfoHubId }) => {
      queryClient.invalidateQueries()
    },
  })
}

export const useUpdateCandidateInfoHubStatus = () => {
  const queryClient = useQueryClient()
  const { projectsService } = useApiClient()

  return useMutation({
    mutationFn: ({
      candidateInfoHubId,
      status,
    }: {
      candidateInfoHubId: string
      status: CandidateStatus
    }) => projectsService.patchCandidateInfoHub(candidateInfoHubId, { status }),
    onSuccess: (_, { candidateInfoHubId }) => {
      queryClient.invalidateQueries()
    },
  })
}

export const useSendNotifications = () => {
  const queryClient = useQueryClient()
  const { projectsService } = useApiClient()

  return useMutation({
    mutationFn: (notifyProjectAction: NotifyProjectAction) =>
      projectsService.sendNotifications(notifyProjectAction),
    onSuccess: (_, { projectId }) => {
      queryClient.invalidateQueries()
    },
  })
}

export const useAddFileToInfoNode = () => {
  const queryClient = useQueryClient()
  const { projectsService } = useApiClient()

  return useMutation({
    mutationFn: ({
      nodeId,
      addFile,
    }: {
      nodeId: string
      addFile: InfoNodeAddFilePatch
    }) => projectsService.updateInfoNodeFile(nodeId, addFile),
    onSuccess: (_, { nodeId }) => {
      queryClient.invalidateQueries()
    },
  })
}

export const useDeleteInfoHub = () => {
  const queryClient = useQueryClient()
  const { projectsService } = useApiClient()

  return useMutation({
    mutationFn: (infoHubId: string) => projectsService.deleteInfoHub(infoHubId),
    onSuccess: (_, infoHubId) => {
      queryClient.invalidateQueries()
    },
  })
}

export const useUpdateProjectInfoHubImage = () => {
  const queryClient = useQueryClient()
  const { projectsService } = useApiClient()

  return useMutation({
    mutationFn: ({
      infoHubId,
      imageId,
    }: {
      infoHubId: string
      imageId: string
    }) => projectsService.patchProjectInfoHub(infoHubId, { imageId }),
    onSuccess: (_, { infoHubId }) => {
      queryClient.invalidateQueries()
    },
  })
}

export const useGetComments = (entityId: string) => {
  const { projectsService } = useApiClient()

  return useQuery({
    queryKey: projectKeys.comments(entityId),
    queryFn: () => projectsService.getComments(entityId),
    enabled: !!entityId,
  })
}

export const useAddComment = () => {
  const queryClient = useQueryClient()
  const { projectsService } = useApiClient()

  return useMutation({
    mutationFn: ({
      entityId,
      comment,
    }: {
      entityId: string
      comment: string
    }) => projectsService.addComment(entityId, comment),
    onSuccess: () => {
      queryClient.invalidateQueries()
    },
  })
}

export const useDeleteComment = () => {
  const queryClient = useQueryClient()
  const { projectsService } = useApiClient()

  return useMutation({
    mutationFn: ({ commentId }: { commentId: string }) =>
      projectsService.deleteComment(commentId),
    onSuccess: () => {
      queryClient.invalidateQueries()
    },
  })
}
