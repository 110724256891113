import { useAuth } from '../../auth/auth-provider'

export const useReadOnly = () => {
  const { me } = useAuth()
  return !me?.roles.some(
    (role) =>
      role === 'ROLE_ADMIN' ||
      role === 'ROLE_RECRUITER' ||
      role === 'ROLE_RECRUITER-ADMIN'
  )
}
