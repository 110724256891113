import React, { memo } from 'react'
import { Handle, Position } from '@xyflow/react'

const GhostInfoNode = memo(
  ({ id, onClick }: { id: string; onClick?: () => void }) => {
    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="bi bi-plus h-100 w-100"
            viewBox="0 0 16 16"
          >
            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
          </svg>
        </div>
      </div>
    )
  }
)

export default GhostInfoNode
