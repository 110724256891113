import React, { useState } from 'react'
import { Alert, Button, Form, Table } from 'react-bootstrap'
import { CompanyContact } from './CompanyEditor'
import { useCreateUser, useCreateUsers } from '../../hooks/queries/use-users'
import { useUpdateCompany } from '../../hooks/queries/use-companies'
import { Company } from '../../types/company'
import { User } from '../../types/user'
import ContactModal from './ContactModal'

const CompanyContacts = ({
  contacts,
  setContacts,
  isLoading,
  currentCompany,
  setError,
}: {
  contacts: CompanyContact[]
  setContacts: React.Dispatch<React.SetStateAction<CompanyContact[]>>
  isLoading: boolean
  currentCompany: Company
  setError: (error: string | null) => void
}) => {
  const [showModal, setShowModal] = useState(false)

  const createUsers = useCreateUsers()
  const createUser = useCreateUser()
  const updateCompany = useUpdateCompany()

  const handleClose = () => {
    setShowModal(false)
  }

  const handleAdd = (newContact: CompanyContact) => {
    if (newContact.firstName && newContact.email) {
      const updatedContacts = [...contacts, newContact]
      setContacts(updatedContacts)
      createUser.mutate(
        { user: newContact as User, roles: ['ROLE_RECRUITER-CLIENT'] },
        {
          onSuccess: (data) => {
            const contactUserIds = [
              ...contacts.filter((c) => !c.isNew).map((c) => c.id!),
              data.id,
            ]
            updateCompany.mutate(
              {
                original: currentCompany,
                edited: {
                  ...currentCompany,
                  contactUserIds,
                },
              },
              {
                onSuccess: () => {
                  handleClose()
                },
                onError: (error: any) => {
                  setError(
                    'Något gick fel när kontaktpersonen skulle sparas. Vänligen försök igen.'
                  )
                  setContacts(contacts)
                },
              }
            )
          },
          onError: (error: any) => {
            setError(
              'Något gick fel när användaren skulle skapas! Kontrollera så att mailadressen är unik.'
            )
            setContacts(contacts)
          },
        }
      )
    }
  }

  const removeContact = (index: number) => {
    const updatedContacts = contacts.filter((_, i) => i !== index)
    setContacts(updatedContacts)

    const contactUserIds = updatedContacts
      .filter((c) => !c.isNew)
      .map((c) => c.id!)

    updateCompany.mutate(
      {
        original: currentCompany,
        edited: {
          ...currentCompany,
          contactUserIds,
        },
      },
      {
        onError: (error: any) => {
          setError(
            'Något gick fel när kontaktpersonen skulle tas bort. Vänligen försök igen.'
          )
          console.error(error)
          setContacts(contacts)
        },
      }
    )
  }

  const isSaving = createUsers.isPending || updateCompany.isPending

  return (
    <>
      <div className="mb-4">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <Form.Label className="mb-0 h5">Kontaktpersoner</Form.Label>
          <Button
            variant="outline-primary"
            onClick={() => setShowModal(true)}
            disabled={isLoading || isSaving}
          >
            <i className="bi bi-person-plus me-2"></i>
            Lägg till kontaktperson
          </Button>
        </div>

        {contacts.length > 0 ? (
          <Table responsive hover>
            <thead>
              <tr>
                <th className="text-muted small fw-normal border-0">Namn</th>
                <th className="text-muted small fw-normal border-0">Email</th>
                <th className="text-muted small fw-normal border-0">Telefon</th>
                <th className="border-0"></th>
              </tr>
            </thead>
            <tbody>
              {contacts.map((contact, index) => (
                <tr key={contact.id ?? index}>
                  <td>
                    {contact.firstName} {contact.lastName}
                  </td>
                  <td>{contact.email}</td>
                  <td>{contact.phone || '-'}</td>
                  <td className="text-end">
                    {contact.isNew && (
                      <Button
                        variant="link"
                        className="text-danger p-0"
                        onClick={() => removeContact(index)}
                        disabled={isLoading || isSaving}
                      >
                        <i className="bi bi-trash"></i>
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <Alert variant="info" className="text-center">
            <i className="bi bi-info-circle me-2"></i>
            Inga kontaktpersoner har lagts till än
          </Alert>
        )}
      </div>
      <ContactModal
        show={showModal}
        onHide={handleClose}
        onSave={(contact) => {
          setContacts([...contacts, contact])
          setShowModal(false)
        }}
        isLoading={isLoading}
      />

      <style>
        {`
          .contact-modal {
            z-index: 1060 !important;
          }
          
          .contact-modal .modal-content {
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
            border: none;
          }
          
          .modal-backdrop.show:nth-child(2) {
            z-index: 1055 !important;
          }
        `}
      </style>
    </>
  )
}

export default CompanyContacts
